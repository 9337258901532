import React, { memo } from 'react';
import { compose } from 'recompose';

import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import FormChooseStep from 'react/pages/product/form/step-0-choose/FormChooseStep';
import classNames from './product-create.module.scss';

const enhancer = compose(
  memo,
);

const ProductCreateFormChoose = () => (
  <ApplicationLayout mainClassName={classNames.container}>
    <FormChooseStep />
  </ApplicationLayout>
);

export default enhancer(ProductCreateFormChoose);
